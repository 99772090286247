import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import dayjs from 'dayjs'
import dayjsBusinessDays from 'dayjs-business-days'
import moment from 'moment'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import CartContext from '../../contexts/Cart'
import { CEPInput } from '../Inputs'
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderRadius: 14,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontSize: '1.7rem',
  },
  label: {
    color: theme.palette.secondary.main,
    '& span': {
      fontSize: '1.5rem',
    },
  },
  totalInvoice: {
    fontSize: '2rem',
  },
  input: {
    maxWidth: 115,
    borderColor: theme.palette.primary.contrastText,
    '& div input': {
      fontSize: '1.65rem',
      color: theme.palette.primary.contrastText,
    },
    '& div::before': {
      borderBottom: '1px solid ' + theme.palette.text.secondary,
    },
  },
  listItem: {
    borderBottom: '1px solid #FFF',
  },
  listItemFrete: {
    marginTop: '-35px',
  },
  title: {
    fontSize: '1.3rem',
    color: theme.palette.text.primary,
    lineHeight: 1,
  },
  subtitle: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    lineHeight: 1.5,
  },
  money: {
    fontSize: '2rem',
    marginLeft: 3,
    marginRight: 3,
    lineHeight: 1.1,
    color: '#000',
  },
  sub: {
    fontSize: '1rem',
  },
  sob: {
    fontSize: '1.2rem',
    verticalAlign: 'top',
  },
  discount: {
    color: theme.palette.secondary.main,
    fontSize: '0.8rem',
    fontStyle: 'italic',
  },
  containerSubtotal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  containerFreteEPrazo: {
    borderBottom: '1px solid #FFF',
  },
}))

const Totals = ({ disableZipCode, zipCode = '', onStartUpdate, onUpdate, progress }) => {
  const classes = useStyles()
  const cartData = useContext(CartContext)
  // const [selectedShipping, setSelectedShipping] = useState(cartData?.shipping || null)
  const [_subTotal, setSubTotal] = useState(null)
  const [_Total, setTotal] = useState(null)
  const [shippingSearch, setShippingSearch] = useState(false)
  const [shipping, setShipping] = useState('')
  const [_zipCode, setZipCode] = useState(zipCode)
  const [zipError, setZipCodeError] = useState({ error: false, message: '' })
  const [discount, setDiscount] = useState(cartData.discount)
  const [deadline, setDeadline] = useState('')
  useEffect(() => {
    updateShippingCost()
    if (cartData.shipping.zip_code) searchShipping(cartData.shipping.zip_code)
  }, [])

  useEffect(() => {
    updateShippingCost()
    if (cartData.shipping.zip_code) searchShipping(cartData.shipping.zip_code)
  }, [cartData.total, cartData.shipping.zip_code, cartData.payment])

  useEffect(() => {
    function updateZipCode() {
      setZipCode(zipCode)
    }
    updateZipCode()
  }, [zipCode])

  function updateSubTotal() {
    const tmpTot = cartData.shipping.cost
      ? cartData.total + cartData.shipping.cost - cartData.discount
      : cartData.total - cartData.discount
    const tot = parseFloat(tmpTot)
      .toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .split(',')
      .slice(0)
    const stot = parseFloat(cartData.total)
      .toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .split(',')
      .slice(0)
    setDiscount(cartData.discount)
    setSubTotal(stot)
    setTotal(tot)
  }

  function updateShippingCost() {
    const tmpTot = cartData.shipping.cost ? cartData.total + cartData.shipping.cost : cartData.total
    const tot = parseFloat(tmpTot)
      .toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
      .split(',')
      .slice(0)

    let cost = 0
    if (cartData.shipping.cost || cartData.shipping.cost === 0) {
      if (cartData.shipping.cost !== 0)
        cost = parseFloat(cartData.shipping.cost)
          .toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .split(',')
          .slice(0)
      setShipping(cost)
    }
    //setTotal(tot);
    updateSubTotal()
  }

  const getShippingCost = async (e) => {
    setZipCode(e.target.value)
    setShippingSearch(false)
    setShipping('')
    setZipCodeError({ error: false, message: '' })
    if (onStartUpdate) onStartUpdate(cartData)

    if (String(e.target.value).length === 8) {
      setShippingSearch(true)
      await searchShipping(e.target.value)
      updateShippingCost()
      setShippingSearch(false)
      if (onUpdate) onUpdate(cartData)
    }
  }

  async function searchShipping(zipCode) {
    try {
      await cartData.shippingCost(zipCode, cartData.itens)
      setDeadline(cartData.shipping.deadline)
    } catch (error) {
      if (error.response) {
        if (error.response.data.errors) setZipCodeError({ error: true, message: error.response.data.errors })
        else if (error.response.data.message)
          setZipCodeError({
            error: true,
            message: error.response.data.message,
          })
      }
    }
  }

  // Previsão de entrega do produto
  dayjs.extend(dayjsBusinessDays)
  const previsao = () => {
    const date = moment()
      // .add(+deadline + 15, 'days')
      .add(+cartData?.shipping?.deadline + 15, 'days')
      .format('DD/MM/YYYY')
    return date
  }

  const handleShippingSelect = (event) => {
    // setSelectedShipping(cartData?.shippingMethodsList?.find((item) => item?.id == event.target.value))
    cartData?.setShipping(cartData?.shippingMethodsList?.find((item) => item?.id == event.target.value))
    updateShippingCost()
    if (onUpdate) onUpdate(cartData)
  }

  return (
    <Fragment>
      <Paper className={classes.root}>
        <List>
          <ListItem className={classes.listItem}>
            <ListItemText primary="Qtd:" className={classes.label} />
            <ListItemSecondaryAction>{cartData.qty}</ListItemSecondaryAction>
          </ListItem>
          <ListItem className={classes.listItem} style={{ minHeight: 88 }}>
            <ListItemText primary="Subtotal:" className={classes.label} />
            <ListItemSecondaryAction>
              {progress && <CircularProgress color="secondary" />}
              {!_subTotal && <Skeleton variant="text" width={120} />}
              {_subTotal && !progress && (
                <div className={classes.containerSubtotal} style={{ marginTop: discount > 0 ? 36 : 'initial' }}>
                  <div>
                    <span className={classes.sub}>R$</span>
                    {_subTotal[0]}
                    <span className={classes.sob}>,{_subTotal[1]}</span>
                  </div>
                  {discount > 0 && (
                    <p className={classes.discount}>
                      Você está economizando{' '}
                      {parseFloat(discount).toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </p>
                  )}
                </div>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Frete:" className={classes.label} />
          </ListItem>
          <ListItem className={classes.listItemFrete}>
            <ListItemText
              primary={
                <CEPInput
                  disabled={disableZipCode}
                  onChange={getShippingCost}
                  value={_zipCode}
                  margin="dense"
                  className={classes.input}
                  color="secondary"
                  label="CEP"
                  name="cep"
                  helperText={zipError.message}
                  error={zipError.error}
                  placeholder="12345-678"
                />
              }
            />
            <ListItemSecondaryAction>
              {shippingSearch && <CircularProgress color="secondary" />}
              {/* {shipping === 0 && !shippingSearch && <span>GRÁTIS</span>} */}
              {shipping.length === 2 && !shippingSearch && (
                <>
                  <span className={classes.sub}>R$</span>
                  {shipping[0]}
                  <span className={classes.sob}>,{shipping[1]}</span>
                </>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          {!!cartData?.shippingMethodsList?.length && (
            <ListItem>
              <FormControl component="fieldset">
                <ListItemText primary="Métodos de Entrega:" className={classes.label} />
                <Box sx={{ textTransform: 'capitalize' }}>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    // value={selectedShipping?.id || 0}
                    value={cartData?.shipping?.id || 0}
                    onChange={handleShippingSelect}
                  >
                    {cartData?.shippingMethodsList?.map((method) => {
                      const isFree = method?.valor * 1 === 0
                      const price = parseFloat(method?.valor)
                        .toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        .split(',')
                        .slice(0)

                      return (
                        <FormControlLabel value={method?.id} control={<Radio />} label={method?.metodoEntregaTexto} />
                      )
                    })}
                  </RadioGroup>
                </Box>
              </FormControl>
            </ListItem>
          )}

          <ListItem>
            <ListItemText primary="Produção:" className={classes.label} />
            <ListItemSecondaryAction>
              {progress && <CircularProgress color="secondary" />}
              {!deadline && <Skeleton variant="text" width={120} />}
              {deadline && !progress && !zipError.error && _zipCode.length != 0 && (
                <>{deadline.length === 0 ? '' : `15 dias`}</>
              )}
            </ListItemSecondaryAction>
          </ListItem>

          {/* <ListItem>
            <ListItemText primary="Transporte:" className={classes.label} />
            <ListItemSecondaryAction>
              {progress && <CircularProgress color="secondary" />}
              {!deadline && <Skeleton variant="text" width={120} />}
              {deadline && !progress && !zipError.error && _zipCode.length != 0 && (
                <>{deadline.length === 0 ? '' : `${deadline * 1} dias`}</>
              )}
            </ListItemSecondaryAction>
          </ListItem> */}

          <ListItem>
            <ListItemText primary="Transporte:" className={classes.label} />
            <ListItemSecondaryAction>
              {progress && <CircularProgress color="secondary" />}
              {!_zipCode && _zipCode?.length != 0 && <Skeleton variant="text" width={120} />}
              {cartData?.shipping?.deadline && !progress && !shippingSearch && !zipError.error && _zipCode && _zipCode?.length != 0 && (
                <>{cartData?.shipping?.deadline?.length === 0 ? '' : `${cartData?.shipping?.deadline * 1} dias`}</>
              )}
            </ListItemSecondaryAction>
          </ListItem>

          {/* <ListItem className={classes.containerFreteEPrazo}>
            <ListItemText primary="Previsão:" className={classes.label} />
            <ListItemSecondaryAction>
              {progress && <CircularProgress color="secondary" />}
              {!deadline && <Skeleton variant="text" width={120} />}
              {deadline && !progress && !zipError.error && _zipCode.length != 0 && (
                <>{deadline.length === 0 ? '' : previsao()}</>
              )}
            </ListItemSecondaryAction>
          </ListItem> */}

          <ListItem className={classes.containerFreteEPrazo}>
            <ListItemText primary="Previsão:" className={classes.label} />
            <ListItemSecondaryAction>
              {progress && <CircularProgress color="secondary" />}
              {!_zipCode && _zipCode?.length != 0 && <Skeleton variant="text" width={120} />}
              {cartData?.shipping?.deadline && !progress && !shippingSearch && !zipError.error && _zipCode && _zipCode?.length != 0 && (
                <>{cartData?.shipping?.deadline?.length === 0 ? '' : previsao()}</>
              )}
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Total:" className={classes.label} />
            <ListItemSecondaryAction className={classes.totalInvoice}>
              {progress && <CircularProgress color="secondary" />}
              {!_Total && <Skeleton variant="text" width={120} />}
              {_Total && !progress && (
                <>
                  <span className={classes.sub}>R$</span>
                  {_Total[0]}
                  <span className={classes.sob}>,{_Total[1]}</span>
                </>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>
    </Fragment>
  )
}

export default Totals
